<template>
    <div>
        <div>
            <shopping-cart-item v-if="localCart" v-for="(cartItem, index) in localCart"
                                :item="cartItem"
                                :index="index"
                                :key="index"
                                :requiresPrintDataCheck="requiresPrintDataCheck"
                                :canChangeQuantity="canChangeQuantity"
            >
            </shopping-cart-item>
            <div v-if="Object.keys(localCart).length > 0 && canSendOrder()" class="col-sm-6 offset-sm-4">
                <button v-on:click="submit()" type="submit" class="btn btn-sm btn-app-color pl-4 pr-4">
                    {{$trans('shop.messages.cart.submitOrder')}}
                </button>
            </div>
        </div>
        <div class="overlay" v-show="loading">
            <square-grid></square-grid>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['cartItems', 'requiresPrintDataCheck', 'canChangeQuantity'],
        data() {
            return {
                localCart: this.cartItems,
                loading: false,
                processingRowIds: []
            }
        },
        mounted() {
            window.bus.$on('removeCartItem', this.removeCartItem);
            if (this.requiresPrintDataCheck) {
                setTimeout(function () {
                    this.refreshPrintDataChecks();
                    setInterval(function () {
                        this.refreshPrintDataChecks();
                    }.bind(this), 5000);
                }.bind(this), 1000);
            }
        },
        methods: {
            refreshPrintDataChecks() {
                let _that = this;
                _that.searchProcessingDatachecks();
                if (_that.hasProcessingDataChecks() === false) {
                    return;
                }

                window.axios.post(route('cart.print-data'), {'rowIds': _that.processingRowIds})
                    .then(function (response) {
                        _that.localCart = response.data.data;
                        _that.searchProcessingDatachecks();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            removeCartItem(index) {
                this.$delete(this.localCart, index);
            },
            submit() {
                let _that = this;
                _that.loading = true;
                axios.post(route('cart.submit', {}), {})
                    .then(function (response) {
                        self.location.href = route('cart.process', {});
                    })
                    .catch(function (error) {
                        console.log(error);
                        location.reload();
                        _that.loading = false;
                    });
            },
            hasProcessingDataChecks() {
                return this.processingRowIds.length > 0;
            },
            hasFailedDataChecks() {
                let _that = this;
                let hasFailedDataChecks = false;
                Object.keys(this.localCart).forEach((key) => {
                    if (_that.localCart[key].options.print_data_check_status === 2) {
                        hasFailedDataChecks = true;
                    }
                });
                return hasFailedDataChecks;
            },
            searchProcessingDatachecks() {
                let _that = this;
                _that.processingRowIds = [];
                Object.keys(this.localCart).forEach((key) => {
                    if (_that.localCart[key].options.print_data_check_status === 0) {
                        if (_that.processingRowIds.indexOf(key) === -1) {
                            _that.processingRowIds.push(key);
                        }
                    }
                });
            },
            canSendOrder() {
                if (this.requiresPrintDataCheck === false) {
                    return true;
                }
                if (this.hasProcessingDataChecks()) {
                    return false;
                }
                if (this.hasFailedDataChecks()) {
                    return false;
                }
                return true;
            }
        }
    }
</script>
